<template lang='pug'>
.main-wrapper.caixa-listarGuias
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-3(v-if='imprimir')
					Button.p-button-secondary.btn-back(v-if='verResumo' icon='jam jam-chevrons-left' @click='$router.go(0)')
				.p-col-3(v-else-if='semCaixa')
					Button.p-button-secondary.btn-back(v-if='rotazero == 0' icon='jam jam-chevrons-left' @click='$router.push(`/agendamento/salvar/${atendimento_id}/`) ')

					Button.p-button-secondary.btn-back(v-else icon='jam jam-chevrons-left' @click='$router.go(-1)')
				.p-col-3(v-else)
					Button.p-button-secondary.btn-back(v-if='verResumo' icon='jam jam-chevrons-left' @click='verResumo = false; selected=[]; selectedAtendimento=null')
				.p-col-9.ta-right
					h1.text-header.text-secondary MedClub / <b>Pagamento</b>

		FecharCaixa(:visible="dialogFechar" :operacional='true' @close="dialogFechar = false")

		Dialog.dialogRemover(header="Remover agendamento" :modal='true' :visible.sync='dialogRemover')
			.ta-center
				p <b>Deseja remover o agendamento?</b>
				p {{ dialogRemover_data.nm_paciente }} - {{ dialogRemover_data.nm_estabelecimento }}
				p <b>{{ dialogRemover_data.nr_valor_total_f }}</b>
			.ta-center.my-4
				ProgressSpinner.waitingRemover-spinner(v-if='waitingRemover' strokeWidth='6')
				Button.p-button-danger.btn-fecharCaixa(v-else label='Remover' icon='jam jam-trash' @click='removerAgendamento()')

		Dialog.dialogRemover(header="Remover agendamentos" :modal='true' :visible.sync='dialogRemoverSelecionados')
			.ta-center
				p <b>Deseja mesmo remover todos os agendamentos selecionados?</b>
			.ta-center.my-4
				ProgressSpinner.waitingRemover-spinner(v-if='waitingRemoverSelecionados' strokeWidth='6')
				Button.p-button-danger.btn-fecharCaixa(v-else label='Remover' icon='jam jam-trash' @click='removerSelecionados()')

		ListarGuiasResumo(
			v-if='verResumo'
			:cd_atendimento_id='selectedAtendimento?.id'
			:waitingResumo='waitingResumo'
			:selected='selected'
			:cd_titular='cd_titular_id'
			:atendimento='selectedAtendimento'
		)
		div(v-else)

			ProgressBar(v-if='waiting' mode="indeterminate")
			.ta-center.my-4(v-else-if='! caixaAberto' style='display: row; align-items: center;')
				ProgressSpinner.waitingMessage-spinner(v-if='waitingMessage' strokeWidth='6')
				div.message-parent(v-else)
					Message.message.ta-center(v-if='isInativo' :severity= 'semCaixa? "error":"warn"' :closable='false') Você possui caixa aberto de dias anteriores.<br/> <a style='cursor:pointer' type='button' @click='fecharCaixa()' >Clique aqui para fechar</a>
					Message.message.ta-center(v-else :severity= 'semCaixa? "error":"warn"' :closable='false') {{semCaixa? 'Você não possui caixas disponíveis': 'Abra um caixa para prosseguir'}}
				AbrirCaixa
			div(v-else)

				TabView(@tab-change='onTabChange')
					TabPanel(header='Carrinho Gestor')

						Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
							.p-grid.p-fluid.p-align-end
								.p-col-12.p-sm-6.p-md-2
									label.form-label Data da reserva:
									.p-inputgroup
										Calendar(
											v-model='filtersAtendimentos.dt_inicio'
											dateFormat="dd/mm/yy" :locale="ptbr"
											:minDate='anteontem'
											:maxDate='hoje'
											:manualInput='false'
											:touchUI='windowInnerWidth < 576'
											:showButtonBar="true"
											@clear-click='applyAtendimentosFilters()')
										Button(icon='jam jam-search' v-tooltip.top="'Pesquisar'" @click='applyAtendimentosFilters()')
										Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" type='button' @click='filtersAtendimentos.dt_inicio=hoje; applyAtendimentosFilters()')

								.p-col-12.p-sm-6.p-md-2
									label.form-label Número:
									.p-inputgroup
										InputText(@keyup.enter.native='applyAtendimentosFilters()' v-model='filtersAtendimentos.id')
										Button(icon='jam jam-search' @click='applyAtendimentosFilters()')

								.p-col-12.p-sm-6.p-md-2
									label.form-label CPF:
									.p-inputgroup
										InputText(@keyup.enter.native='applyAtendimentosFilters()' v-model='filtersAtendimentos.nr_cpf')
										Button(icon='jam jam-search' @click='applyAtendimentosFilters()')

								.p-col-12.p-sm-6.p-md-4
									label.form-label Cliente:
									.p-inputgroup
										InputText(@keyup.enter.native='applyAtendimentosFilters()' v-model='filtersAtendimentos.nm_pessoa_fisica')
										Button(icon='jam jam-search' @click='applyAtendimentosFilters()')

								// .p-col-12.p-md-6
									label.form-label CPF:
									.p-inputgroup
										InputMask(@keydown.enter='applyFilters()' v-model='filters.nr_cpf'
											@keyup='checkEmptyField("nr_cpf")' mask='999.999.999-99')
										Button(icon='jam jam-search' @click='applyFilters()')
						
						Panel.datatable( header='Lista de atendimentos')
							ProgressBar(v-if='waitingAtendimentos' mode="indeterminate")
							.ta-center(v-else-if='!atendimentos.length')
								p Não existem atendimentos.
							div(v-else)
								Paginator.mb-1(
									:rows='paginatorAtendimento.per_page'
									:first='(paginatorAtendimento.page - 1) * paginatorAtendimento.per_page'
									:totalRecords='paginatorAtendimento.count'
									@page="onPageAtendimento($event)")

								DataTable(:value='atendimentos' :selection.sync="selectedAtendimento"
									selectionMode="single" dataKey="id" @row-select='onAtendimentoSelect($event.data.cd_pessoa_fisica.cd_pai?.id || $event.data.cd_pessoa_fisica.id)')
									Column(headerStyle='width:12%' bodyStyle='text-align:center' header='N° atendimento')
										template(#body='props')
											span {{ $root.padStart(props.data.id, 8) }}
									Column(header='Cliente' field='cd_pessoa_fisica.nm_pessoa_fisica')
										template(#body='{data}')
											span {{data.cd_pessoa_fisica.nm_pessoa_fisica}}
											br
											ul(:style="{'margin': '0'}")
												li(v-for="hs in unique(data.horarioagendamento_set, 'cd_paciente.id')" v-if='hs.cd_paciente_id != data.cd_pessoa_fisica.id' :stlye="{'fontSize':'8px'}") {{ hs.cd_paciente.nm_pessoa_fisica }}
									Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Usuario' field='nm_usuario_cri')
									Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Data' field='dt_realizado')
									Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Qtd' field='horarioagendamento_set.length')
									Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Valor total' field='nr_valor_total_f')
									Column(headerStyle='width:5%' bodyStyle='text-align:center')
										template(#body='props')
											Button.p-button-rounded(icon='jam jam-chevron-right' @click='selectedAtendimento = props.data; onAtendimentoSelect(props.data.cd_pessoa_fisica.cd_pai?.id || props.data.cd_pessoa_fisica.id)')

								Paginator.mb-1(
									:rows='paginatorAtendimento.per_page'
									:first='(paginatorAtendimento.page - 1) * paginatorAtendimento.per_page'
									:totalRecords='paginatorAtendimento.count'
									@page="onPageAtendimento($event)")

					TabPanel(header='Carrinho App/Site')

						Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
							.p-grid.p-fluid.p-align-end

								.p-col-12.p-md-4
									label.form-label Nome:
									.p-inputgroup
										InputText( @keyup.enter.native='filters.nm_pessoa_fisica.length<3 ? "" : applyFilters()' v-model='filters.nm_pessoa_fisica'
											@keyup='checkEmptyField("nm_pessoa_fisica")' )
										Button(icon='jam jam-search' @click='applyFilters()' :disabled='filters.nm_pessoa_fisica.length<3')

								.p-col-12.p-md-2
									label.form-label CPF:
									.p-inputgroup
										InputMask(@keydown.enter='applyFilters()' v-model='filters.nr_cpf'
											@keyup='checkEmptyField("nr_cpf")' mask='999.999.999-99')
										Button(icon='jam jam-search' @click='applyFilters()')

								.p-col-12.p-sm-6.p-md-3
									label.form-label Data da reserva:
									.p-inputgroup
										Calendar(
											v-model='filters.dt_agendamento'
											dateFormat="dd/mm/yy" :locale="ptbr"
                                            :minDate='anteontem'
											:maxDate='hoje'
											:manualInput='false'
											:touchUI='windowInnerWidth < 576'
											:showButtonBar="true"
											@clear-click='applyFilters()')
										Button(icon='jam jam-search' v-tooltip.top="'Pesquisar'" @click='applyFilters()')
										Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" type='button' @click='filters.dt_agendamento=null; applyFilters()')

								.p-col-12.p-sm-6.p-md-3
									label.form-label Data da agenda:
									.p-inputgroup
										Calendar(
											v-model='filters.dt_agenda'
											dateFormat="dd/mm/yy" :locale="ptbr"
											:manualInput='false'
											:touchUI='windowInnerWidth < 576'
											:showButtonBar="true"
											@clear-click='applyFilters()')
										Button(icon='jam jam-search' v-tooltip.top="'Pesquisar'" @click='applyFilters()')
										Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" type='button' @click='filters.dt_agenda=null; applyFilters()')

						CustomDataTable(:waiting='waitingGuias' :header="'Lista de Guias'" :tValue='list' :tData.sync='tableData')
					
					TabPanel(header='Carrinho MedEmpresa')

						Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
							.p-grid.p-fluid.p-align-end
								.p-col-12.p-sm-6.p-md-2
									label.form-label Data da reserva:
									.p-inputgroup
										Calendar(
											v-model='filters.dt_inicio'
											dateFormat="dd/mm/yy" :locale="ptbr"
											:minDate='anteontem'
											:maxDate='hoje'
											:manualInput='false'
											:touchUI='windowInnerWidth < 576'
											:showButtonBar="true"
											@clear-click='applyFilters()')
										Button(icon='jam jam-search' v-tooltip.top="'Pesquisar'" @click='applyFilters()')
										Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" type='button' @click='filters.dt_inicio=hoje; applyFilters()')

								.p-col-12.p-sm-6.p-md-2
									label.form-label Data da agenda:
									.p-inputgroup
										Calendar(
											v-model='filters.dt_agenda'
											dateFormat="dd/mm/yy" :locale="ptbr"
											:minDate='anteontem'
											:maxDate='hoje'
											:manualInput='false'
											:touchUI='windowInnerWidth < 576'
											:showButtonBar="true"
											@clear-click='applyFilters()')
										Button(icon='jam jam-search' v-tooltip.top="'Pesquisar'" @click='applyFilters()')
										Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" type='button' @click='filters.dt_agenda=hoje; applyFilters()')

								.p-col-12.p-sm-6.p-md-2
									label.form-label Número:
									.p-inputgroup
										InputText(@keyup.enter.native='applyFilters()' v-model='filters.id')
										Button(icon='jam jam-search' @click='applyFilters()')

								.p-col-12.p-sm-6.p-md-2
									label.form-label CPF:
									.p-inputgroup
										InputText(@keyup.enter.native='applyFilters()' v-model='filters.nr_cpf')
										Button(icon='jam jam-search' @click='applyFilters()')

								.p-col-12.p-sm-6.p-md-4
									label.form-label Cliente:
									.p-inputgroup
										InputText(@keyup.enter.native='applyFilters()' v-model='filters.nm_pessoa_fisica')
										Button(icon='jam jam-search' @click='applyFilters()')
							
							Panel.datatable( header='Lista de atendimentos')
								ProgressBar(v-if='waitingGuias' mode="indeterminate")
								.ta-center(v-else-if='!list.length')
									p Não existem atendimentos.
								div(v-else)
									Paginator.mb-1(
										:rows='paginator.per_page'
										:first='(paginator.page - 1) * paginator.per_page'
										:totalRecords='paginator.count'
										@page="onPage($event)")

									DataTable(:value='list' dataKey="id" @row-select='onAtendimentoSelect($event.data.cd_pessoa_fisica.cd_pai?.id || $event.data.cd_pessoa_fisica.id)')
										Column(headerStyle='width:12%' bodyStyle='text-align:center' header='N° atendimento')
											template(#body='props')
												span {{ $root.padStart(props.data.id, 8) }}
										Column(header='Cliente' field='cd_pessoa_fisica.nm_pessoa_fisica')
											template(#body='{data}')
												span {{data.cd_pessoa_fisica.nm_pessoa_fisica}}
												br
												span <b> Saldo empresa: </b> {{formatPrice(data.empresa_associado.nr_saldo)}}
												br
												ul(:style="{'margin': '0'}")
													li(v-for="hs in unique(data.horarioagendamento_set, 'cd_paciente.id')" v-if='hs.cd_paciente_id != data.cd_pessoa_fisica.id' :stlye="{'fontSize':'8px'}") {{ hs.cd_paciente.nm_pessoa_fisica }}
										Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Usuario' field='nm_usuario_cri')
										Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Data' field='dt_atualizado_f')
										Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Qtd' field='horarioagendamento_set.length')
										Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Valor total' field='nr_valor_total_f')
										Column(headerStyle='width:9%' bodyStyle='text-align:center')
											template(#body='props')
												Button.p-button-rounded.p-button-success(
													v-if='props.data.empresa_associado.nr_saldo > props.data.valor_total'
													v-tooltip.top="'Autorizar guias'"
													icon='jam jam-coin'
													@click='autorizarEmpresa(props.data.id)'
												)
												Button.p-button-rounded(
													v-else
													icon='jam jam-chevron-right'
													@click='selectedAtendimento = props.data; onAtendimentoSelect(props.data.cd_pessoa_fisica.cd_pai?.id || props.data.cd_pessoa_fisica.id)'
												)

									Paginator.mb-1(
										:rows='paginator.per_page'
										:first='(paginator.page - 1) * paginator.per_page'
										:totalRecords='paginator.count'
										@page="onPage($event)")

				.ta-right.mt-4
					.p-grid
						.p-col-6.ta-left
							Button.p-button-warning(label='Fechar o caixa' icon="jam jam-power" @click="dialogFechar = true")
						.p-col-6
							Button.p-button-danger(v-if='selected.length' label='Remover selecionados' icon="jam jam-trash" @click="dialogRemoverSelecionados = true")
							Button.ml-2(v-if='selected.length' label='Pagar' icon="jam jam-coin" @click="verResumo = true")
</template>

<style lang="scss">
	.caixa-listarGuias {
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
		}
		.btn-abrirCaixa {
			font-size: 20px;
			font-weight: 700;
			.jam {
				font-size: 20px;
			}
		}
		.message-parent {
			width: 100%;
			display: flex !important;
			justify-content: center;
		}
		.message {
			width: 500px;
		}
		.waitingRemover-spinner {
			width: 32px;
			height: auto;
		}
		.waitingMessage-spinner {
			width: 32px;
			height: auto;
		}
		.spinner-caixasfechados {
			height: 40px;
		}
		.spinner-abrirCaixa, .spinner-fecharCaixa {
			height: 29px;
			width: 29px;
		}
		.dialogRemover {
			width: 95%; max-width: 500px;
			.p-dialog-content {
				h1 {
					font-size: 18px;
				}
				p {
					margin: 4px 0;
				}
			}
		}

		.link-limpar {
			text-decoration: underline;
			font-size: 12px;
			cursor: pointer;
			font-weight: 700;
			margin-right: 10px;
			display: inline-block;
			margin-bottom: 6px;
		}
		.text-soma {
			margin-bottom: 4px;
			margin-right: 10px;
			display: inline-block;
			background-color: #faf3dd;
			box-shadow: 0 1px 2px #ddd;
			padding: 6px 10px;
			font-size: 14px;
		}
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import ProgressSpinner from 'primevue/progressspinner'
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Button from 'primevue/button'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Calendar from 'primevue/calendar'
	import Column from 'primevue/column'
	import Dropdown from 'primevue/dropdown'
	import Checkbox from 'primevue/checkbox'
	import Dialog from 'primevue/dialog'
	import InputText from 'primevue/inputtext'
	import InputMask from 'primevue/inputmask'
	import Tooltip from 'primevue/tooltip'
	import TabView from 'primevue/tabview'
	import TabPanel from 'primevue/tabpanel'
	import Message from 'primevue/message';

	import ListarGuiasResumo from './ListarGuias.resumo'
	import FecharCaixa from './FecharCaixa'
	import { Caixas, Agendamento, MedEmpresa } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	import moment from 'moment'
	import {pCalendarLocale_ptbr} from "../../utils"
	import axios from "axios"
	import AbrirCaixa from "@/components/Caixa/AbrirCaixa";
	import CustomDataTable from '../CustomComponents/CustomDataTable.vue'

	const _ = require('lodash')

	export default {
		beforeRouteEnter (to, from, next) {
			next(vm => {
				if (from.name === 'agendamento/salvar')
					vm.rotazero = from.params.id
			})
		},
		created () {
			this.waiting = true

			Caixas.checkCaixaAtivo().then(response => {
				if (response.status == 400) {
					this.waiting = false
					this.$toast.info(response.data.detail, { duration: 3000 })
				} else {
					this.waiting = false;

					if(!response.data.ie_ativo){
						this.caixaAbertoId = response.data.id;
						this.isInativo = true;
						// this.$toast.error("Feche o caixa para continuar", { duration: 3000 });
						// this.$router.push({path: `/historico-operacional/visualizar/${response.data.id}/?inativo=true`});
					} else {
						this.caixaAberto = true
						if (this.atendimento_id) {
							this.filtersAtendimentos.id = this.atendimento_id
						}

                        this.applyAtendimentosFilters().then( () => {
                            this.selectedAtendimento = this.atendimentos.find(a => a.id == this.atendimento_id)
                            if( this.selectedAtendimento ){
                                this.onAtendimentoSelect(this.selectedAtendimento.cd_pessoa_fisica.id)
                                this.$router.replace( { query: { id: null } } )
                            }
                        })
					}
				}
			})
		},
		components: { AbrirCaixa, Message, ProgressBar, DataView, Panel, Button, Paginator, Dropdown, InputText, InputMask, TabView,
			TabPanel, DataTable, Calendar, Column, Checkbox, ListarGuiasResumo, ProgressSpinner, Dialog, Tooltip, FecharCaixa, CustomDataTable },
        directives: { tooltip: Tooltip },
		data () {
			return {
				rotazero: null,
				list: [],
				selected: [],
				atendimentos: [],
				selectedAtendimento: null,
				ptbr: pCalendarLocale_ptbr,
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingGuias: false,
				waitingAtendimentos: false,
				verResumo: false,
				imprimir: false,
				filters: {
					nm_pessoa_fisica: '',
					nr_cpf: '',
                    dt_agendamento: null,
                    dt_agenda: new Date(),
				},
				filtersDefault: {
					nm_pessoa_fisica: '',
					nr_cpf: '',
                    dt_agendamento: null,
                    dt_agenda: new Date(),
				},
				filtersAtendimentos: {
					nm_pessoa_fisica: '',
					nr_cpf: '',
					id: '',
					dt_inicio: moment()._d,
				},
				filtersAtendimentosDefault: {
					nm_pessoa_fisica: '',
					nr_cpf: '',
					id: '',
					dt_inicio: moment()._d,
				},
				paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				paginatorAtendimento: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				atendimento_id: this.$route.query.id ? this.$route.query.id : null,
				caixaAberto: false,
				dialogFechar: false,
				dialogRemover: false,
				isInativo: false,
				caixaAbertoId: null,
				dialogRemover_data: {},
				waitingRemover: false,
				dialogRemoverSelecionados: false,
				waitingRemoverSelecionados: false,
				cancelToken: null,
				params: {},
				waitingMessage: true,
				semCaixa: false,
				getListDebounce: _.debounce(function (params, getFunction) {
					getFunction(params)
				}, 500, { leading: true }),
				sum () {
					if (! this.selected) return 0
					let sum = 0
					this.selected.forEach(item => { sum += item.nr_valor_total })
					return sum
				},
				tableData: [
					{header: 'Código do Titular', field: 'cd_titular_id', hStyle: {width:'10%'},
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
					{header: 'Nome do Titular', field: 'nm_pessoa_fisica', hStyle: {width:'30%'},
                        bStyle: {
                            textAlign: 'left'
                        },
						body: [
							{
								bType: 'custom',
								custom: this.nome_titular
							}
						]
                    },
					{header: 'Pacientes', field: 'pacientes', hStyle: {width:'30%'},
                        bStyle: {
                            textAlign: 'left'
                        },
						body: [
							{
								bType: 'custom',
								custom: this.pacientes
							}
						]
                    },
					{header: 'Qtd. Horários', field: 'qtd_horarios', hStyle: {width:'10%'},
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
					{header: 'Valor Total', field: 'nr_valor_total', hStyle: {width:'10%'},
                        bStyle: {
                            textAlign: 'center'
                        },
						body:[
							{
								bType: 'custom',
								custom: this.valor_total
							}
						]
                    },
					{header: '', field: '', hStyle: {width:'5%'},
                        body: [
                            {
                                bType: 'button',
                                icon: 'jam jam-chevron-right',
                                classes: 'p-button-rounded',
                                tooltip: 'Ver Resumo',
                                action: this.onGuiaAppSiteSelect,
                            }
                        ],
                        bStyle: {
                            textAlign: 'center',
                            padding: 0
                        }
                    },
				],
				waitingResumo: false,
				cd_titular_id: null,
				hoje: moment().toDate(),
				anteontem: moment().subtract(2, 'days').toDate(),
				tipo_atendimento: null,
			}
		},
		methods: {
			autorizarEmpresa(params) {
				MedEmpresa.autorizarCompra({cd_atendimento: params}).then(response => {
					if ([200, 201, 204].includes(response.status))	{
						this.$toast.success('Guias autorizadas!', { duration: 3000 })
						if(response.data.cd_transacao_financeira) {
							window.open(`/transacoes-financeiras/visualizar/${response.data.cd_transacao_financeira}/`, '_blank')
						}
						this.selected = [];
						this.filters = this.filtersDefault
						this.applyFilters(0,true)
					} else if (response.status == 400) {
						if (response.data.non_field_errors)
							response.data.non_field_errors.forEach(errorMsg => {
								this.$toast.error(errorMsg, { duration: 3000 })
							})
						else Object.values(response.data).forEach(errorMsg => {
							if (typeof errorMsg == 'object')
								this.$toast.error(errorMsg[0], { duration: 3000 })
							else this.$toast.error(errorMsg, { duration: 3000 })
						})
					}
				})
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			onAtendimentoSelect (cd_titular) {
				this.cd_titular_id = cd_titular
				this.selected = this.selectedAtendimento.horarioagendamento_set
				this.verResumo = true
			},
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
			getList (params) {
				if (this.cancelToken) this.cancelToken.cancel()
				this.cancelToken = axios.CancelToken.source()

				this.waitingGuias = true
				let funcao = this.tipo_atendimento ? MedEmpresa['getAtendimentos'] : Caixas['getAgendamentosNovo']
				return funcao(params, this.cancelToken.token).then(response => {
					if (!response) return
					if (response.status == 200) {
						this.paginator.count = response.data.count
						response.data.results.forEach(ag => {
							if(this.tipo_atendimento) {
								if (ag.nr_cpf) ag.nr_cpf_f = ag.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
								let nr_valor_total = 0
								ag.horarioagendamento_set.forEach(horario => {
									horario.nr_valor_total_f =
										new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
											.format(horario.nr_valor_total)
									horario.nr_valor_total_antigo_f =
										new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
											.format(horario.nr_valor_total_antigo)
									if (horario.nr_cpf) horario.nr_cpf_f = horario.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
									if (horario.cd_paciente.cd_pai) horario.cd_paciente.cd_pai.nr_cpf_f = horario.cd_paciente.cd_pai.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
									nr_valor_total += parseFloat(horario.nr_valor_total)
								})
								if (! ag.cd_pessoa_fisica) ag.cd_pessoa_fisica = { nm_pessoa_fisica: null }
								ag.dt_atualizado_f = moment(ag.dt_atualizado).format('DD/MM/YYYY - HH:mm')
								ag.dt_realizado = moment(ag.dt_atualizado).format('DD/MM/YYYY')
								ag.hr_realizado = moment(ag.dt_atualizado).format('HH:mm')
								ag.nr_valor_total_f = this.formatPrice(nr_valor_total)
							} else {
								ag.nr_valor_total_f =
									new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
										.format(ag.nr_valor_total)
								if (ag.nr_cpf) ag.nr_cpf_f = ag.nr_cpf
							}
						})
						this.list = response.data.results
					}
					this.waitingGuias = false
					this.cancelToken = null
					return new Promise(resolve => { resolve(true) })
				})
			},
			applyFilters (page, force) {
				this.paginator.page = page || 1
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => {
					if (this.filters[key]) {
						if (key === 'nr_cpf') params.nr_cpf = this.filters[key].replace(/(\.|-|_)/g, '')
						else if (key === 'dt_agendamento' || key === 'dt_agenda' || key === 'dt_inicio') {
							params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
						} else params[key] = this.filters[key]
					}
				})

				if (!params.dt_agenda && ! params.dt_agendamento && !params.nr_cpf && !params.nm_pessoa_fisica) {
					this.$toast.error("Use pelo menos um dos filtros.", { duration: 3000 })
					return new Promise(resolve => { resolve(true) })
				}
				if(this.tipo_atendimento) params['ie_status'] = 'A'
				// Fake loading quando os filtros forem os mesmos
				if (_.isEqual(this.params, params) && !force) {
					if (!this.waitingGuias) {
						this.waitingGuias = true
						setTimeout(() => this.waitingGuias = false, 300)
					}
				} else {
					this.params = Object.assign({}, params)
					this.waitingGuias = true
					this.getListDebounce(params, this.getList)
				}
				return new Promise(resolve => { resolve(true) })
			},
			getAtendimentosList (params) {
				this.waitingAtendimentos = true

				return Agendamento.getAtendimentos(params).then(response => {
					this.waitingAtendimentos = false
					this.atendimentos = []

					if (response.status === 200) {
						this.paginatorAtendimento.count = response.data.count
						this.atendimentos = response.data.results.filter(atendimento => atendimento.horarioagendamento_set.length > 0)
						this.atendimentos.forEach(atendimento => {
							let nr_valor_total = 0
							atendimento.horarioagendamento_set.forEach(ag => {
								ag.nr_valor_total_f =
									new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
										.format(ag.nr_valor_total)
								ag.nr_valor_total_antigo_f =
									new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
										.format(ag.nr_valor_total_antigo)
								if (ag.nr_cpf) ag.nr_cpf_f = ag.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
								if (ag.cd_paciente.cd_pai) ag.cd_paciente.cd_pai.nr_cpf_f = ag.cd_paciente.cd_pai.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
								nr_valor_total += parseFloat(ag.nr_valor_total)
							})
							if (! atendimento.cd_pessoa_fisica) atendimento.cd_pessoa_fisica = { nm_pessoa_fisica: null }
							atendimento.dt_realizado = moment(atendimento.dt_atualizado).format('DD/MM/YYYY')
							atendimento.hr_realizado = moment(atendimento.dt_atualizado).format('HH:mm')
							atendimento.nr_valor_total_f = this.formatPrice(nr_valor_total)
						})
					}

					return new Promise(resolve => { resolve(this.atendimentos) })
				})
			},
			applyAtendimentosFilters (page) {
				this.paginatorAtendimento.page = page || 1
				let params = {
					paginacao: true,
					page: this.paginatorAtendimento.page,
					per_page: this.paginatorAtendimento.per_page
				}

				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filtersAtendimentos).forEach((key) => {
					if (this.filtersAtendimentos[key]) params[key] = this.filtersAtendimentos[key]
					if (key === 'dt_inicio') {
						params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
					}
				})

				return this.getAtendimentosList(params)
			},
			onPage (ev) {
				this.applyFilters(ev.page + 1)
			},
			onPageAtendimento (ev) {
				this.applyAtendimentosFilters(ev.page + 1)
			},
			onTabChange (event) {
				this.tipo_atendimento = null
				if (event.tab.header == 'Carrinho App/Site') {
					this.selected = [];
					this.filters = this.filtersDefault
					this.applyFilters(0,true)
				} else if (event.tab.header == 'Carrinho MedEmpresa') {
					this.tipo_atendimento = 'E'
					this.selected = [];
					this.filters = this.filtersDefault
					this.applyFilters(0,true)
				} else {
					this.selectedAtendimento = []
					this.filtersAtendimentos = this.filtersAtendimentosDefault
					this.applyAtendimentosFilters(0)
				}
			},
			removerAgendamento () {
				this.waitingRemover = true
				Agendamento.reservarHorario({ cd_horario_agendamento: this.dialogRemover_data.id, ie_reservar: 'L' }).then(response => {
					this.waitingRemover = false
					if (response.status == 200) {
						this.dialogRemover_data = {}
						this.dialogRemover = false
						this.applyFilters(this.paginator.page, true)
						this.applyAtendimentosFilters()
						this.$toast.success('Agendamento removido', { duration: 3000 })
					} else if (typeof response?.data?.detail == 'string') {
                        this.$toast.error(response.data.detail, { duration: 4000 })
                    }
				})
			},
            removerSelecionados () {
                this.waitingRemoverSelecionados = true
                let _remover = (idx) => {
                    if (idx === this.selected.length) {
                        this.waitingRemoverSelecionados = false
						this.dialogRemoverSelecionados = false
                        this.$toast.success('Agendamentos removidos', { duration: 3000 })
						this.selected = []
						this.applyFilters(this.paginator.page, true)
						this.applyAtendimentosFilters()
						return 0
                    }
					const dataSend = {cd_horario_agendamento: this.selected[idx].id, ie_reservar: 'L'}
                    Agendamento.reservarHorario(dataSend).then(() => _remover(++idx))
                }; _remover(0)
            },
			fecharCaixa(){
				this.$router.push(`/historico-operacional/visualizar/${this.caixaAbertoId}`);
			},
			limparSelecao () {
				this.selected = []
			},
			nome_titular(item) {
				return `
						<b> ${ item.nm_pessoa_fisica } </b>
						<br />
						<em> CPF: ${ item.nr_cpf } </em>
						<br />
					`
			},
			pacientes(item){
				let pacientes = ''
				_.each(item.pacientes, i => {
					pacientes = pacientes +
									`<li>
										${this.nome_titular({
												nm_pessoa_fisica: i.nm_paciente,
												nr_cpf: i.nr_cpf_paciente
											})}
									</li>
									`
							})
				return `
						<ul>
							${pacientes}
						</ul>

					`
			},
			valor_total(item){
				return this.formatPrice(item.nr_valor_total)
			},
			onGuiaAppSiteSelect(data){
				this.waitingResumo = true
				this.cd_titular_id = data.cd_titular_id
				this.verResumo = true
				Caixas.getAgendamentos({cd_titular: this.cd_titular_id}).then(res => {
					if (res.status == 200) {
						this.paginator.count = res.data.count
						res.data.forEach(ag => {
							ag.nr_valor_total_f =
								new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
									.format(ag.nr_valor_total)
							if (ag.nr_cpf) ag.nr_cpf_f = ag.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
							if (ag.cd_paciente.cd_pai != undefined) ag.cd_paciente.cd_pai.nr_cpf_f = ag.cd_paciente.cd_pai.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
						})
						this.selected = res.data
					}
					this.waitingResumo = false
				})
			},
			unique (list, prop) {
				return _.uniqBy(list, prop)
			}
		}
	}
</script>
