<template>
    <div class="abrir-caixa">

        <Panel  header="Abrir caixa" class="ta-left">
            <div class="p-grid p-fluid">
                <div :class="['p-col-12', { 'form-group--error': submitted && $v.model.cd_caixa.$error }]">
                    <label class="form-label">Caixa:</label>
                    <ProgressBar v-if="waiting.caixas" mode="indeterminate" />
                    <Dropdown
                        v-else
                        v-model="model.cd_caixa"
                        :options="options.caixas"
                        optionLabel="text"
                        optionValue="value"
                        dataKey="value"
                        filter>
                        <template #option='props'>
                            <span><b> {{ props.option.text }} </b></span><br>
                            <span> {{ props.option.textAlt }} </span>
                        </template>
                    </Dropdown>
                    <div v-if="submitted && $v.model.cd_caixa.$error" class="feedback--errors">
                        <div v-if="!$v.model.cd_caixa.required" class="form-message--error">
                            <b>Caixa</b> é obrigatório.
                        </div>
                    </div>
                </div>
                <div class="p-col-12 ta-center">
                    <ProgressSpinner class="spinner-abrir-caixa" v-if="waiting.abrirCaixa" strokeWidth="6" />
                    <Button v-else label="Abrir caixa" icon="jam jam-screen" @click="abrirCaixa()" />
                </div>
            </div>
        </Panel>
    </div>
</template>

<style lang="scss">
.abrir-caixa {
    max-width: 500px;
    margin: 0 auto;
    .btn-abrir-caixa {
        font-size: 20px;
        font-weight: 700;
        .jam {
            font-size: 20px;
        }
    }
    .spinner-abrir-caixa {
        height: 29px;
        width: 29px;
    }
}
</style>

<script>
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Panel from "primevue/panel";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
// import Message from 'primevue/message';

import {Caixas} from "./../../middleware";
import {required} from "vuelidate/lib/validators";

export default {
    components: { Button,  Dropdown, Panel, ProgressBar, ProgressSpinner},
    created() {
        this.getCaixas();
    },
    data () {
        return {
            submitted: false,
            model: {
                cd_caixa: null,
            },
            options: {
                caixas: [],
            },
            waiting: {
                abrirCaixa: false,
                caixas: false,
            }
        }
    },
    validations () {
        return {
            model: {
                cd_caixa: { required },
            }
        }
    },
    methods: {
        listarCaixas() {
            this.getCaixas();
        },
        getCaixas () {
            this.options.caixas = [];
            this.waiting.caixas = true;
            Caixas.getCaixasFechados().then(response => {
                this.waiting.caixas = false;
                if (response.status === 200) {
                    this.options.caixas = response.data.map(caixa => {
                        return { text: caixa.nm_caixa, textAlt: caixa.nm_unidade, value: caixa.id }
                    });
                    if(!this.options.caixas.length) {
                        this.$parent.semCaixa = true;
                        // this.$toast.error("Você não possui nenhuma permissão de caixa", { duration: 3000 });
                    }
                }
               this.$parent.waitingMessage = false;
            })            
        },
        abrirCaixa () {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.waiting.abrirCaixa = true;

            const dataSend = Object.assign({}, this.model);
            Caixas.abrirCaixaOperacional(dataSend).then(response => {
                this.waiting.abrirCaixa = false;
                if (([200, 201]).includes(response.status)) this.$router.go(this.$route.query.retorno? -1 : 0);
                else if (response.status === 400) {
                    if (response.data.detail) {
                        if (typeof response.data.detail == 'string') {
                            this.$toast.error(response.data.detail, { duration: 3000 });
                        } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                    } else if (response.data.non_field_errors) {
                        response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                    }
                }
            })
        }
    }
}
</script>
